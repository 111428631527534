import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import { CountUp } from "countup.js"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"
import Demo from "../../components/demo"

import Basketball from "../../images/icons/basketball.svg";
import EcommerceCart from "../../images/icons/ecommerce-cart.svg";
import Shop from "../../images/icons/shop.svg";

import DatabaseCheck from "../../images/icons/database-check.svg";
import SegmentsPersonalization from "../../images/icons/segments-personalization.svg";
import PersonalizedCampaigns from "../../images/icons/personalized-campaigns.svg";

import SzopexGray from "../../images/icons/clients/szopex-gray.svg";
import SzopexColor from "../../images/icons/clients/szopex-color.svg";

class IndexPage extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    const counterOptions = {
      duration: 2,
      separator: ' ',
      suffix: '+'
    };

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-2', {
      delay: 100
    });

    ScrollReveal().reveal('.sr-3', {
      delay: 200
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });

    ScrollReveal().reveal('.sr-c-1', {
      delay: 0,
      beforeReveal: function () {
        const counterYears = new CountUp('counter-years', 29, counterOptions);
        counterYears.start();
      }
    });

    ScrollReveal().reveal('.sr-c-2', {
      delay: 100,
      beforeReveal: function () {
        const counterShops = new CountUp('counter-shops', 10, counterOptions);
        counterShops.start();
      }
    });

    ScrollReveal().reveal('.sr-c-3', {
      delay: 200,
      beforeReveal: function () {
        const counterUsers = new CountUp('counter-users', 1000000, counterOptions);
        counterUsers.start();
      }
    });

    ScrollReveal().reveal('.sr-q', {
      delay: 100,
      beforeReveal: function () {
        document.getElementsByClassName('sr-q')[0].classList.add('is-loaded');
      }
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Success Story: How a Polish eCommerce Business has increased the OR of Its Emails by 64% Thanks to ExpertSender’s Multichannel Marketing Automation Platform" image="https://expertsender.com/images/og/szopex-success-story.jpg" />
        <Hero title="<span class='mar-b-2'>Success Story </span>How a&nbsp;Polish eCommerce Business has&nbsp;increased the&nbsp;OR of&nbsp;Its Emails by&nbsp;64%&nbsp;Thanks to&nbsp;ExpertSender’s Multichannel Marketing Automation Platform" classes="heroes--singless heroes--szopex" industry="eCommerce" industryURL="/success-stories/ecommerce" button="false" anim="false" extracol="yes" />

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="column column--centered sr-hidden sr-1">
                  <div class="section-h section-h--full section-h--small">
                    <p class="section-h__3 has-text-weight-bold has-text-justified">Online stores collect a&nbsp;lot of data on their customers but usually, they aren’t aware of&nbsp;the&nbsp;value that this information presents. The level of growth indicators resulting from the&nbsp;implementation of a&nbsp;data-driven multichannel marketing automation platform can surprise even the&nbsp;greatest of optimists.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
        </div>

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="section-c columns mar-t-3">
            <div className="column sr-hidden sr-c-1">
              <div id="counter-years" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                years in the market
              </div>
            </div>
            <div className="column sr-hidden sr-c-2">
              <div id="counter-shops" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                online stores
              </div>
            </div>
            <div className="column sr-hidden sr-c-3">
              <div id="counter-users" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                monthly visitors
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="columns is-multiline is-variable is-8 is-vcentered">
            <div className="column is-full-tablet is-half-widescreen sr-hidden sr-1">
              <div className="section-h section-h--full section-h--small">
                <h2 className="section-h__2 mar-b-2">
                  Client
                </h2>
                <p className="section-h__3 has-text-justified">Szopex is a&nbsp;Polish company that has been selling sports footwear and&nbsp;the&nbsp;clothing of the world's biggest brands (including Adidas, Nike, and Reebok) since 1991. In addition to&nbsp;its 27 stationary stores throughout the&nbsp;country, Szopex has also been selling online via&nbsp;its 10&nbsp;specialized online stores. These eStores are visited by&nbsp;over one million users monthly.</p>
              </div>
            </div>
            <div className="column is-full-tablet is-half-widescreen">
              <div className="columns has-text-centered is-vcentered is-multiline">
                <div className="column is-half mar-b-1 sr-hidden sr-1">
                  <div className="sss__logo">
                    <Img fixed={this.props.data.logo1g.childImageSharp.fluid} />
                    <Img fixed={this.props.data.logo1c.childImageSharp.fluid} />
                  </div>
                </div>
                <div className="column is-half mar-b-1 sr-hidden sr-2">
                  <div className="sss__logo">
                    <Img fixed={this.props.data.logo2g.childImageSharp.fixed} />
                    <Img fixed={this.props.data.logo2c.childImageSharp.fixed} />
                  </div>
                </div>
                <div className="column is-half mar-b-1 sr-hidden sr-1">
                  <div className="sss__logo">
                    <Img fixed={this.props.data.logo3g.childImageSharp.fixed} />
                    <Img fixed={this.props.data.logo3c.childImageSharp.fixed} />
                  </div>
                </div>
                <div className="column is-half mar-b-1 sr-hidden sr-2">
                  <div className="sss__logo">
                    <Img fixed={this.props.data.logo4g.childImageSharp.fixed} />
                    <Img fixed={this.props.data.logo4c.childImageSharp.fixed} />
                  </div>
                </div>
                <div className="column is-half mar-b-1 sr-hidden sr-1">
                  <div className="sss__logo">
                    <Img fixed={this.props.data.logo5g.childImageSharp.fixed} />
                    <Img fixed={this.props.data.logo5c.childImageSharp.fixed} />
                  </div>
                </div>
                <div className="column is-half mar-b-1 sr-hidden sr-2">
                  <div className="sss__logo">
                    <Img fixed={this.props.data.logo6g.childImageSharp.fixed} />
                    <Img fixed={this.props.data.logo6c.childImageSharp.fixed} />
                  </div>
                </div>
                <div className="column is-half mar-b-1 sr-hidden sr-1">
                  <div className="sss__logo">
                    <Img fixed={this.props.data.logo7g.childImageSharp.fixed} />
                    <Img fixed={this.props.data.logo7c.childImageSharp.fixed} />
                  </div>
                </div>
                <div className="column is-half mar-b-1 sr-hidden sr-2">
                  <div className="sss__logo">
                    <Img fixed={this.props.data.logo8g.childImageSharp.fixed} />
                    <Img fixed={this.props.data.logo8c.childImageSharp.fixed} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="section-h section-h--full section-h--small">
            <h2 className="section-h__2 mar-b-2">
              Industries
            </h2>
          </div>
          <div className="section-c columns mar-t-3">
            <div className="column sr-hidden sr-1">
              <Basketball className="section-i__ico" />
              <div className="section-c__desc mar-t-1">
                Sport
              </div>
            </div>
            <div className="column sr-hidden sr-2">
              <EcommerceCart className="section-i__ico" />
              <div className="section-c__desc mar-t-1">
                eCommerce
              </div>
            </div>
            <div className="column sr-hidden sr-3">
              <Shop className="section-i__ico" />
              <div className="section-c__desc mar-t-1">
                Retail
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="columns is-multiline is-variable is-8 is-vcentered">
            <div className="column is-full-tablet is-half-widescreen sr-hidden sr-1">
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.email.childImageSharp.fluid} className="section-player" />
                </div>
              </div>
            </div>
            <div className="column is-full-tablet is-half-widescreen sr-hidden sr-2">
              <div className="section-h section-h--full section-h--small">
                <h2 className="section-h__2 mar-b-2">
                  Goals
                </h2>
                <p className="section-h__3 has-text-justified">In order to&nbsp;streamline the&nbsp;work of&nbsp;its online stores, the&nbsp;company needed an&nbsp;easy-to-use and&nbsp;efficient tool for the&nbsp;automated distribution of&nbsp;large numbers of&nbsp;email and SMS messages to&nbsp;separate subscriber lists. Efficient multiple variable subscriber list segmentation and high deliverability were also important features. Szopex was hoping to&nbsp;improve both the&nbsp;open rate and the conversion rate of&nbsp;the messages they were sending. When looking for a&nbsp;new email marketing and&nbsp;marketing automation solution, Szopex turned to&nbsp;ExpertSender who had contacted the company in&nbsp;the&nbsp;past.</p>
              </div>

              <div className="section-h section-h--full section-h--small mar-t-3">
                <h2 className="section-h__2 mar-b-2">
                  Solution
                </h2>
                <p className="section-h__3 has-text-justified">After some initial tests, Szopex decided on&nbsp;ExpertSender, a&nbsp;multichannel marketing automation platform that not only supports email and SMS channels, but also offers an&nbsp;extensive system of&nbsp;data tables that can be&nbsp;used for all types of&nbsp;messages. The option of&nbsp;freely combining data from multiple tables opened up virtually unlimited opportunities for&nbsp;message segmentation.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-b-6">
          <div className="columns is-centered">
            <div className="column is-three-quarters sr-hidden sr-1">
              <h2 className="section-h__3 has-text-secondary has-text-centered">
                <strong className="has-text-secondary">Sklep Biegacza</strong> saw an&nbsp;<strong className="has-text-secondary">OR increase of&nbsp;63%</strong> for&nbsp;email.
              </h2>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="columns is-centered is-variable is-8">
            <div className="column column--centered sr-hidden sr-1">
              <div className="section-h section-h--full section-h--small">
                <h2 className="section-h__2 mar-b-2">
                  Results
                </h2>
                <p className="section-h__3 has-text-justified mar-b-1">Implementation of&nbsp;ExpertSender has allowed Szopex to&nbsp;organize and automate the&nbsp;sending of&nbsp;newsletters for each of&nbsp;their 10&nbsp;online stores. It also enabled better subscriber list segmentation which in&nbsp;2019 translated into an increase of the overall message open rate (OR) relative to&nbsp;2017 by&nbsp;24%. For particular online stores, these numbers were as&nbsp;follows: Worldbox – increase by&nbsp;44%, Sklep Koszykarza – by&nbsp;41%, Sklep Biegacza – by&nbsp;63%. Also, the share of sales coming from automated messages in&nbsp;the total sales from email increased globally by&nbsp;approx.&nbsp;115% (for the Worldbox and Sklep Korzykarza stores this increase was about tenfold, and&nbsp;for Sklep Biegacza – up to&nbsp;twelve-fold).</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-b-6">
          <div className="columns is-centered">
            <div className="column is-three-quarters sr-hidden sr-1">
              <h2 className="section-h__3 has-text-secondary has-text-centered">
                The share of&nbsp;sales coming from automated messages in&nbsp;the&nbsp;total sales from email <strong className="has-text-secondary">increased globally by&nbsp;approx.&nbsp;115%</strong>.
              </h2>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="columns is-centered is-variable is-8">
            <div className="column column--centered sr-hidden sr-1">
              <div className="section-h section-h--full section-h--small">
                <p className="section-h__3 has-text-justified">Currently, Szopex employees use ExpertSender on&nbsp;a&nbsp;daily basis which has&nbsp;significantly improved their email marketing workflow.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-b-6">
          <div className="columns is-centered">
            <div className="column is-three-quarters">
              <div className="section-quote sr-hidden sr-q">
                <div className="section-quote__inner card-ss">
                  <div className="card-ss__logo mar-b-2">
                    <SzopexGray style={{width: "158px"}} />
                    <SzopexColor style={{width: "158px"}} />
                  </div>
                  <p className="section-quote__text">What we&nbsp;were looking for were: a&nbsp;trouble-free solution, high deliverability of&nbsp;messages, good communication with the customer support, and minimal involvement of&nbsp;our IT department. ExpertSender successfully met most of&nbsp;these expectations.</p>
                  <div class="card-ss__sign card-ss__sign--bigger mar-t-2">
                    Agnieszka Stankiewicz
                    <span>CRM Analyst</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-t-3 mar-t-6 mar-b-3">
          <div className="columns is-variable is-8 is-vcentered">
            <div className="column sr-hidden sr-1">
              <div className="section-h section-h--full">
                <h2 className="section-h__2">
                  Technology Used
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-t-2 pad-b-1 mar-b-6">
          <div className="columns is-variable is-6 mar-b-2">
            <div className="column sr-hidden sr-1">
              <DatabaseCheck className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">Data Tables</h3>
              <p>Allow you to&nbsp;store external client data (e.g. a&nbsp;customer’s purchase history) and&nbsp;use them for&nbsp;advanced segmentation.</p>
            </div>
            <div className="column sr-hidden sr-2">
              <SegmentsPersonalization className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">Dynamic Segmentation</h3>
              <p>Allows for&nbsp;hassle-free segment calculations in real-time based on&nbsp;purchase and&nbsp;behavioral data.</p>
            </div>
            <div className="column sr-hidden sr-3">
              <PersonalizedCampaigns className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">Data-Driven Personalization</h3>
              <p>Allows for&nbsp;the use of cross-channel and&nbsp;behavioral data to&nbsp;create a&nbsp;unique customer experience.</p>
            </div>
          </div>
        </div>

        <Demo props={this.props.data} />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    logo1g: file(relativePath: { eq: "success-stories/szopex/sklepkoszykarza-gray.png" }) {
      childImageSharp {
        fluid(maxWidth: 240, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    logo1c: file(relativePath: { eq: "success-stories/szopex/sklepkoszykarza-color.png" }) {
      childImageSharp {
        fluid(maxWidth: 240, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    logo2g: file(relativePath: { eq: "success-stories/szopex/sklepbiegacza-gray.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo2c: file(relativePath: { eq: "success-stories/szopex/sklepbiegacza-color.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo3g: file(relativePath: { eq: "success-stories/szopex/warsawsneakerstore-gray.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo3c: file(relativePath: { eq: "success-stories/szopex/warsawsneakerstore-color.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo4g: file(relativePath: { eq: "success-stories/szopex/sklepsiatkarza-gray.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo4c: file(relativePath: { eq: "success-stories/szopex/sklepsiatkarza-color.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo5g: file(relativePath: { eq: "success-stories/szopex/sportowysklep-gray.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo5c: file(relativePath: { eq: "success-stories/szopex/sportowysklep-color.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo6g: file(relativePath: { eq: "success-stories/szopex/zgodafc-gray.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo6c: file(relativePath: { eq: "success-stories/szopex/zgodafc-color.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo7g: file(relativePath: { eq: "success-stories/szopex/worldbox-gray.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo7c: file(relativePath: { eq: "success-stories/szopex/worldbox-color.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo8g: file(relativePath: { eq: "success-stories/szopex/butydlamalucha-gray.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    logo8c: file(relativePath: { eq: "success-stories/szopex/butydlamalucha-color.png" }) {
      childImageSharp {
        fixed(width: 240, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    email: file(relativePath: { eq: "success-stories/szopex/szopex-email.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    esDashboard: file(relativePath: { eq: "expertsender-dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
